/*******************
 Breadcrumb and page title
*******************/

.page-breadcrumb {
    padding: 20px 20px 0 20px;  
    .page-title{
        margin-bottom: 0px;
        font-weight: $font-weight-medium;
        font-size: 21px;
        
    }
    .breadcrumb {
        padding: 0px;
        margin: 0px;
        background: transparent;
        font-size: 12px;
        .breadcrumb-item+.breadcrumb-item::before {
            content: "\e649";
            font-family: themify;
            color: $gray-400;
            font-size: 11px;
        }
    }
}
