.sales5 {
  position: relative;
  height: 278px;

  .ct-series-a .ct-area {
    fill-opacity: 0.2;
    fill: url(#gradient);
  }

  .ct-series-a .ct-line,
  .ct-series-a .ct-point {
    stroke: $info;
    stroke-width: 2px;
  }

  .ct-series-b .ct-area {
    fill: $success;
    fill-opacity: 0.1;
  }

  .ct-series-b .ct-line,
  .ct-series-b .ct-point {
    stroke: $success;
    stroke-width: 2px;
  }

  .ct-series-a .ct-point,
  .ct-series-b .ct-point {
    stroke-width: 6px;
  }
}

.ct-series-a .ct-bar {
  stroke: $info;
}

.ct-series-b .ct-bar {
  stroke: $gray-200;
}



@media(max-width:767px) {
  .ct-bar {
    stroke-width: 10px !important;
  }
}
