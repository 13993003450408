.todo-widget {
    .todo-list {
        .todo-item {
            border: 0px;
            margin-bottom: 0px;
            padding: 20px 15px 15px 0px;
            .custom-checkbox {
                width: 100%;
                .todo-label {
                    font-weight: 400;
                    width: 100%;
                }
                .custom-control-input:checked ~ .todo-label {
                    .todo-desc {
                        text-decoration: line-through;
                        opacity: 0.7;
                    }
                    .badge {
                        text-decoration: line-through;
                    }
                }
            }
            &:hover{
                z-index: 0;
            }
        }
    }
}

.app-sidebar {
    right: 0;
    position: fixed;
    width: 0;
    top: 64px;
    height: 100%;
    box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
    transition: 0.2s ease-in;
    z-index: 99;
}