/* You can add global styles to this file, and also import other style files */

@import url("https://fonts.googleapis.com/css?family=Rubik:300,400,500,700,900");
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300&display=swap');
html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Montserrat-Regula;
    font-family: Montserrat-Regular !important;
}

.custom-dialog-container .mat-dialog-container {
    padding: 0 !important;
    margin: 0 !important;
}

.upcoming-custom-dialog-container .mat-dialog-container {
    padding: 0 !important;
    margin: 0 !important;
    background: #03213F;
    overflow: hidden;
}

.user-info .mat-dialog-container
{
    padding: 16px 0px 10px 16px;
    background-color: white;
}

/* login  form height fix and the model in funnyverse */

@media (min-width: 301px) and (max-width: 399px) {
    .dialog-responsive .mat-dialog-container {
        height: 80vh !important;
    }
    .upcoming-custom-dialog-container .mat-dialog-container {
        height: 80vh !important;
    }
}

@media (min-width: 400px) and (max-width: 499px) {
    .dialog-responsive .mat-dialog-container {
        height: 84vh !important;
    }
    .upcoming-custom-dialog-container .mat-dialog-container {
        height: 84vh !important;
    }
}

.full-screen-modal {
    max-width: unset !important;
    width: 100%;
    height: 100%;
    padding: 0 !important;
    margin: 0 !important;
}

.full-screen-modal .mat-dialog-container {
    max-width: 100vw;
    max-height: 100vh;
    height: 100%;
    width: 100%;
    padding: 0 !important;
    margin: 0 !important;
}

.full-screen-modal .mat-dialog-container .mat-dialog-content {
    max-height: unset !important;
    padding: 0 !important;
    margin: 0 !important;
}

.video-dialog-container .mat-dialog-container {
    padding: 0 !important;
    margin: 0 !important;
    overflow: hidden !important;
    background-color: black;
}

.cdk-overlay-pane.my-dialog {
    position: relative !important;
}

.close.mat-button {
    position: absolute;
    top: 0;
    left: 0;
    padding: 5px;
    line-height: 14px;
    min-width: auto;
    border: none;
}

.vc-container .form-container {
    position: absolute;
    left: 10%;
    top: 10%;
    width: 70%;
    height: 50%;
    background-color: rgba(255, 255, 255, 0.9);
    border: 1px solid white;
    padding: 20px;
    color: black;
    display: none;
}

.vc-container .button {
    border: 1px solid blue;
    background: lightblue;
    display: block;
    margin: 5px;
    padding: 0px 5px;
}

.vc-container .button:hover {
    color: black;
}

.vc-container .clickable {
    cursor: pointer;
}

.vc-container a {
    color: blue;
}

.dialog-responsive {
    width: 20%;
}

.dialog-responsive-1 {
    width: 70%;
}

.mat-dialog-content {
    background: rgb(0 0 0 / 2%);
}

.live .slick-prev {
    left: -50px !important;
    margin-top: -40px !important;
}

.nav .slick-track {
    margin-left: 0;
}

.live .slick-track {
    margin-left: 0;
}

.saj .slick-track {
    margin-left: 0;
}

.landing .slick-track {
    margin-left: 0;
}

.landing .slick-next {
    right: -50px !important;
    /* margin-top: -35px !important; before*/
    margin-top: -58px !important;
}

.landing .slick-prev {
    left: -60px !important;
    /* margin-top: -35px !important;before */
    margin-top: -68px !important;
}

.loginbtn-1 {
    width: 100%;
    height: 45px;
    border-radius: 5px;
    background: linear-gradient( 90deg, #ff9e5e, #de007e);
    border-color: #fe9660 !important;
    color: #fff;
}

@media (min-width: 768px) and (max-width: 1024px) {
    .dialog-responsive {
        width: 50%;
    }
}

@media (min-width: 1024px) and (max-width: 1366px) {
    .dialog-responsive {
        width: 30%;
    }
}

@media only screen and (min-width: 1281px) {
    .dialog-responsive {
        width: 20%;
    }
}

.remove-overflow {
    overflow-x: hidden;
}

mat-tab-body div.mat-tab-body-content {
    overflow-x: hidden;
}

@media only screen and (max-width: 760px) {
    .dialog-responsive {
        width: 100%;
    }
}

.nav-select .mat-form-field-type-mat-select:not(.mat-form-field-disabled) .mat-form-field-flex {
    cursor: pointer;
    background-color: rgba(74, 126, 161, 1) !important;
    font-family: Montserrat-Regular;
}

.nav-select .mat-select-value {
    color: aliceblue;
    font-weight: 500;
    font-family: Montserrat-Regular;
}

.nav-select .mat-select-arrow {
    color: rgba(210, 210, 210, 1);
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 8px solid;
    margin: 0 4px;
}

.custom-tab-bg .mat-tab-labels {
    background: #E8ECED;
}


/* .custom-tab-bg .mat-tab-label-content {
    color: rgb(5 103 135);
    font-weight: 700;
    font-family: sans-serif;
} */

.custom-tab-bg mat-ink-bar {
    background: #E8ECED !important;
}

.bookfreeclass-matTabGroup .mat-tab-labels {
    background: #0c0755;
}

.bookfreeclass-matTabGroup .mat-tab-label-content {
    color: white;
}

.bookfreeclass-matTabGroup mat-ink-bar {
    background: #126d79 !important;
}

.bookfreeclass-matTabGroup #mat-tab-label-0-0 {
    width: 50% !important;
}

.bookfreeclass-matTabGroup #mat-tab-label-0-1 {
    width: 50% !important;
}

.custom-dialog-container .mat-dialog-container {
    overflow-y: hidden;
}

.signup-metadata-dialog-container .mat-dialog-container {
    overflow: hidden;
    padding: 0px !important;
}

.google-signup-metadata-dialog-container .mat-dialog-container {
    overflow: hidden;
    padding: 0px !important;
}

element.style {
    transform-origin: left top;
}

.mat-menu-panel {
    overflow: hidden !important;
    min-height: 49px !important;
}

.spanel .mat-menu-panel {
    min-height: 49px !important;
}

.mat-menu-item-submenu-trigger::after {
    border-style: none !important;
    border-width: 0 !important;
    border-color: none !important;
    font-family: Montserrat-Regular !important;
}

.mclass .carousel-arrow {
    margin-right: -100px !important;
    color: blue !important;
}

.mclass .carousel-arrow-prev {
    margin-left: -100px !important;
    color: blue !important;
}

.mclass .carousel-arrow-disabled {
    margin-left: -100px !important;
    color: blue !important;
}

.header-text {
    font: normal normal 300 20px Montserrat-Regular !important;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    cursor: pointer;
}

.header-text a {
    color: #ffff;
}

.header-text a:hover {
    color: grey;
}

.spanel ::ng-deep.mat-menu-content.dropdown {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    background-color: #0c0755;
}

.mat-menu-content {
    overflow: scroll;
    background-color: #246788;
    max-height: 75vh;
}


/************* Loading spinner *********/

.loading {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: none;
}


/* Transparent Overlay */

.loading:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
}


/* :not(:required) hides these rules from IE9 and below */

.loading:not(:required) {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

.loading:not(:required):after {
    content: '';
    display: block;
    font-size: 15px;
    width: 1em;
    height: 1em;
    margin-top: -0.5em;
    -webkit-animation: spinner 1500ms infinite linear;
    -moz-animation: spinner 1500ms infinite linear;
    -ms-animation: spinner 1500ms infinite linear;
    -o-animation: spinner 1500ms infinite linear;
    animation: spinner 1500ms infinite linear;
    border-radius: 0.5em;
    -webkit-box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0, rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
    box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) -1.5em 0 0 0, rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
}


/* Animation */

@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-moz-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-o-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}


/******** load end *****/

.backdropBackground {
    background-color: none;
    opacity: 0.5%
}

.scroll-hide-container .mat-dialog-container {
    overflow-x: hidden;
    padding: 0px !important
}

.n-html-video-player .mat-dialog-container {
    margin: 0px !important;
    padding: 0px !important;
    background-color: black !important;
}

.slick-slider {
    width: 100%;
    margin: auto;
    /* background-color: lightslategray !important; */
}

body .slick-prev,
body .slick-next {
    height: 40px;
    width: 40px;
    z-index: 100;
}

body .slick-prev::before {
    font-size: 50px;
    line-height: 1;
    color: #8d8c89;
    ;
}

body .slick-next::before {
    font-size: 50px;
    line-height: 0;
    color: #8d8c89;
    /* margin-left: 5px; */
}


/* .nav .slick-next { 
    right: -50px !important;
} */

.nav .slick-prev {
    /* left: -26px !important; 17t*/
    left: -38px !important;
    margin-top: -26px;
}

.nav .slick-next {
    right: -33px !important;
    margin-top: -20px;
    /* left: 342px; */
}

.saji .slick-prev {
    left: -6px !important;
}


/* saji .slick-next{
    margin-right: -40px;
} */

.saji .slick-slider {
    width: 93% !important;
}

.live .slick-next {
    right: -12px !important;
    margin-top: -40px !important;
}

@media only screen and (min-width: 300px)and (max-width:399px) {
    .live .slick-next {
        right: -1px!important;
        margin-top: -33px!important;
    }
    .live .slick-prev {
        left: -4px !important;
        margin-top: -40px !important;
    }
}

@media only screen and (min-width: 360px) and (max-width: 383px) {
    body .slick-next::before {
        font-size: 40px;
        line-height: 0;
        /* changes*/
        color: #8d8c89;
        margin-left: -10px;
        opacity: 1;
    }
    body .slick-prev::before {
        font-size: 40px;
        line-height: 1;
        color: #8d8c89;
        margin-left: 7px;
    }
}

@media only screen and (max-width:499px)and (min-width: 400px) {
    .live .slick-next {
        right: -50px !important;
        margin-top: -41px !important;
    }
    body .slick-next::before {
        font-size: 50px;
        line-height: 1.2;
        color: #8d8c89;
        margin-left: -5px !important;
    }
    body .slick-prev::before {
        font-size: 50px;
        line-height: 1.2;
        color: #8d8c89;
        margin-left: 7px;
    }
    /*new added 17 */
    .nav .slick-next {
        right: -33px !important;
        margin-top: -48px !important;
    }
    .nav .slick-prev {
        left: -52px !important;
        margin-top: -48px !important;
    }
}

@media (min-width:790) and (max-width:830) {
    .landing .slick-prev {
        right: 10px!important;
        /* margin-top: 5px; */
    }
}

.mobileLoginDilog .mat-dialog-container {
    background: transparent linear-gradient( 180deg, #00445a 0%, #E5E5E6 100%) 0% 0% no-repeat padding-box;
}

.nav-background-dialog-container {
    background-color: aqua;
}

.kTab .mat-tab-link {
    background-color: rgb(23, 1, 123);
    opacity: 1 !important;
}

.kTab .mat-tab-label:hover {
    background-color: #E8ECED;
    opacity: 1 !important;
    color: #056888;
}

.kTab .mat-tab-label-active {
    background-color: #056787;
    opacity: 1 !important;
    color: #ffff;
}

.mat-tab-label,
.mat-tab-link {
    color: rgb(5 103 134);
}

.mat-tab-link:hover {
    color: #ffff;
}

.kTab .mat-tab-label {
    height: 48px;
    padding: 0 24px;
    cursor: pointer;
    box-sizing: border-box;
    opacity: 1;
    min-width: 50%;
    text-align: center;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    position: relative;
}

.withoutArrow .mat-select-arrow {
    border: none;
}

.notification-dialog .mat-dialog-container {
    margin: 0px !important;
    padding: 0px !important;
    overflow-x: hidden;
}


/** mobil otp login popup query starts here**/

@media only screen and (min-width:300px) and (max-width:399px) {
    .mobileLoginDilog .mat-dialog-container {
        background: transparent linear-gradient( 180deg, #00445a 0%, #E5E5E6 100%) 0% 0% no-repeat padding-box;
        padding: 16px;
        max-height: 300px;
    }
}

@media only screen and (min-width:400px) and (max-width:499px) {
    .mobileLoginDilog .mat-dialog-container {
        background: transparent linear-gradient( 180deg, #00445a 0%, #E5E5E6 100%) 0% 0% no-repeat padding-box;
        max-height: 300px;
    }
}

@media only screen and (min-width:700px) and (max-width:991px) {
    .mobileLoginDilog .mat-dialog-container {
        background: transparent linear-gradient( 180deg, #00445a 0%, #E5E5E6 100%) 0% 0% no-repeat padding-box;
        max-height: 270px;
    }
}

@media only screen and (min-width: 1024px) and (max-width:1300px) {
    .mobileLoginDilog .mat-dialog-container {
        background: transparent linear-gradient( 180deg, #00445a 0%, #E5E5E6 100%) 0% 0% no-repeat padding-box;
        max-height: 300px;
    }
}


/** mobil otp login popup query ends here**/

@media only screen and (min-width: 540px) and (max-width: 720px) {
    .kTab .mat-tab-label {
        height: 48px;
        padding: 0 24px;
        cursor: pointer;
        box-sizing: border-box;
        opacity: .6;
        min-width: 50% !important;
        text-align: center;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
        position: relative;
    }
}

@media only screen and (min-width: 360px) and (max-width: 640px) {
    .mat-tab-body-wrapper {
        position: relative;
        overflow: hidden;
        display: flex;
        width: 100%;
        transition: height 500ms cubic-bezier(0.35, 0, 0.25, 1);
    }
    .kTab .mat-tab-label {
        height: 48px;
        padding: 0 24px;
        cursor: pointer;
        box-sizing: border-box;
        opacity: .6;
        min-width: 50%;
        text-align: center;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
        position: relative;
    }
    .mat-ink-bar {
        position: absolute;
        bottom: 0;
        height: 2px;
        width: 100% !important;
        transition: 500ms cubic-bezier(0.35, 0, 0.25, 1);
    }
}


/* .container .slick-prev {
    left: -50px !important;
    margin-top: -12px !important;
} */


/* *,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

.s-live { 
  box-sizing: border-box !important;
  font-size: 62.5% !important; 
} */

.grid {
    display: grid;
    width: 114rem;
    grid-gap: 6rem;
    grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
    align-items: start;
}

@media (max-width: 60em) {
    .grid {
        grid-gap: 3rem;
    }
}

.grid__item {
    background-color: #fff;
    border-radius: 0.4rem;
    overflow: hidden;
    box-shadow: 0 3rem 6rem rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: 0.2s;
}

.grid__item:hover {
    transform: translateY(-0.5%);
    box-shadow: 0 4rem 8rem rgba(0, 0, 0, 0.2);
}

.card-l {
    margin: 10px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    /* width: 300px; */
    height: 398px;
}

.card__img {
    width: 108%;
    height: auto;
    /* border-radius: 10px; */
    margin-left: -8px;
    cursor: pointer;
}

.card__content {
    padding: 0rem 0rem;
}

.card__header {
    color: black;
    font-size: 15px;
    cursor: pointer;
    letter-spacing: 1px;
    /* overflow-wrap: break-word; */
    display: -webkit-box;
    word-wrap: break-word;
    line-height: 16px;
    max-height: 32px;
    overflow: hidden !important;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    /* number of lines to show */
    -webkit-box-orient: vertical;
    /* margin-bottom: 2.5rem; */
    min-height: 32px !important;
}

.card__text {
    font-size: 12px !important;
    letter-spacing: 0.1rem;
    line-height: 1.7;
    color: #504c4c;
    letter-spacing: 1px;
    width: 176px;
    display: -webkit-box;
    word-wrap: break-word;
    line-height: 16px;
    max-height: 32px;
    overflow: hidden !important;
    text-overflow: ellipsis;
    -webkit-line-clamp: 4;
    /* number of lines to show */
    -webkit-box-orient: vertical;
    /* margin-bottom: 2.5rem; */
    min-height: 32px !important;
}

.card__btn {
    display: block;
    width: 100%;
    padding: 0.2rem;
    font-size: 1rem;
    text-align: center;
    color: red;
    background-color: #e6ecff;
    border: none;
    border-radius: 10px;
    transition: 0.2s;
    cursor: pointer;
    position: absolute;
    bottom: 20px;
    left: 0px;
    cursor: pointer;
}

.card__btn span {
    margin-left: 1rem;
    transition: 0.2s;
}

.card__btn:hover,
.card__btn:active {
    background-color: #dce4ff;
}

.card__btn:hover span,
.card__btn:active span {
    margin-left: 1.5rem;
}

.icon-play {
    color: #FEE74E;
    font-size: 45px;
    position: absolute;
    top: 20%;
    opacity: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.card {
    box-shadow: rgba(8, 8, 8, 0.19) 0px 10px 20px, rgba(7, 7, 7, 0.23) 0px 6px 6px;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset; */
}

.tag {
    background: #cccccc;
    border-radius: 50px;
    font-size: 11px;
    margin: 0;
    color: #fff;
    padding: 5px 10px;
    text-transform: uppercase;
    cursor: pointer;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
}

.tag-teal {
    background-color: #47bcd4;
}

.tag-purple {
    background-color: #5e76bf;
}

.tag-pink {
    background-color: #cd5b9f;
}

.megMat .mat-tab-label-content {
    color: #2371C7 !important;
    font-size: 18px;
    font-weight: 800 !important;
    font-family: Montserrat-Regular;
}

.megMat mat-button {
    font-family: Montserrat-Regular;
}

.stateD {
    background-color: #006788;
}


/* added by vinay */


/* .mat-dialog-container {
    display: block;
    padding: 24px;
    border-radius: 4px;
    box-sizing: border-box;
    overflow: auto;
    outline: 0;
    width: 100%;
    height: 100% !important;
    min-height: inherit;
    max-height: inherit;
} */


/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/


/* @media (min-width: 1281px) {
    .mat-dialog-container {
        display: block;
        padding: 24px;
        border-radius: 4px;
        box-sizing: border-box;
        overflow: auto;
        outline: 0;
        width: 100%;
        height: 65% !important;
        min-height: inherit;
        max-height: inherit;
    }
} */


/* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */


/* @media (min-width: 1025px) and (max-width: 1280px) {
    .mat-dialog-container {
        display: block;
        padding: 24px;
        border-radius: 4px;
        box-sizing: border-box;
        overflow: auto;
        outline: 0;
        width: 100%;
        height: 24% !important;
        min-height: inherit;
        max-height: inherit;
    }
} */


/* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */


/* @media (min-width: 768px) and (max-width: 1024px) {
    .mat-dialog-container {
        display: block;
        padding: 24px;
        border-radius: 4px;
        box-sizing: border-box;
        overflow: auto;
        outline: 0;
        width: 100%;
        height: 22% !important;
        min-height: inherit;
        max-height: inherit;
    }
} */


/* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */


/* @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .mat-dialog-container {
        display: block;
        padding: 24px;
        border-radius: 4px;
        box-sizing: border-box;
        overflow: auto;
        outline: 0;
        width: 100%;
        height: 22% !important;
        min-height: inherit;
        max-height: inherit;
    }
} */


/* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */


/* @media (min-width: 481px) and (max-width: 767px) {
    .mat-dialog-container {
        display: block;
        padding: 24px;
        border-radius: 4px;
        box-sizing: border-box;
        overflow: auto;
        outline: 0;
        width: 100%;
        height: 13% !important;
        min-height: inherit;
        max-height: inherit;
    }
} */


/* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */


/* @media (min-width: 320px) and (max-width: 480px) {
    .mat-dialog-container {
        display: block;
        padding: 24px;
        border-radius: 4px;
        box-sizing: border-box;
        overflow: auto;
        outline: 0;
        width: 100%;
        height: 13% !important;
        min-height: inherit;
        max-height: inherit;
    }
} */


/* Mat Stepper controlling */

.mat-horizontal-stepper-header-container {
    overflow-x: auto;
}

.mat-step-header {
    min-width: fit-content;
}

.swal2-popup {
    font-size: 0.9rem !important;
}


/* watch our live classes for iphone 11 */


/* @media only screen and (min-width: 414px) and (max-width: 896px) {
    /* body .slick-prev::before {
        font-size: 50px;
        line-height: 1;
        color: #8d8c89;
        margin-left: 35px;
    } */


/* @media only screen and (min-width: 390px) and (max-height: 844px) {
    .resize1 {
        height: auto;
        border-radius: 5px;
        border-color: yellow;
        border-style: solid;
        border-width: 2px;
        margin-left: 37px;
    }
} */

@media only screen and (min-width: 390px) and (max-height: 844px) {
    .tab-content>.active {
        margin-left: -60px;
    }
}


/* @media (min-width: 767px) and (max-width: 1264px) {
    .vcontainer{
        position: relative;
        width: 100%;
        max-width: 100%;
        height: auto;
    }
}  */


/* @media only screen and (min-width: 768px) and (max-width: 1280px) { */


/* .nav .slick-next {
        left: -33px !important;
        margin-top: -52px !important;
    } */


/* .nav .slick-prev {
        left: -45px !important;
        margin-top: -45px !important;
    } */


/*for discover video*/


/* .slick-slider {
        width: 90%;
        margin-left: 16px;
    } */


/* body .slick-prev::before {
        font-size: 50px;
        line-height: 2;
        color: #8d8c89;
    } */


/*for personal coaching 5&10 batches*/


/* @media only screen and (min-width: 1024px) and (max-width: 1366px) {
    .fav-class {
        flex: 0 0 100%!important;
        max-width: 35%!important;
    }
} */


/* for surface and above 500-670 confg */

@media only screen and (min-width: 500px) and (max-width: 670px) {
    .nav .slick-next {
        right: -3px !important;
        margin-top: -12px !important;
    }
    .nav .slick-prev {
        left: -58px !important;
        margin-top: -20px !important;
    }
}


/*for 300-350px*/

@media only screen and (min-width: 300px) and (max-width: 350px) {
    body .slick-next::before {
        font-size: 45px !important;
    }
    .landing .slick-next {
        right: -42px !important;
        margin-top: -42px !important;
    }
    body .slick-prev::before {
        font-size: 45px !important;
    }
    .landing .slick-prev {
        left: -55px !important;
        margin-top: -59px !important;
    }
}

@media only screen and (min-width: 700px) and (max-width: 765px) {
    .landing .slick-next {
        right: -31px !important;
        margin-top: -69px !important;
    }
    .landing .slick-prev {
        left: -43px !important;
        margin-top: -88px !important;
    }
}


/*for icon building legends**/

@media only screen and (min-width: 300px) and (max-width: 599px) {
    .upcoming-custom-dialog-container2 .mat-dialog-container {
        padding: 0!important;
        margin: 0!important;
        background: #03213f;
        overflow: hidden;
        height: 80vh!important;
    }
}

.upcoming-custom-dialog-container2 .mat-dialog-container {
    padding: 0!important;
    margin: 0!important;
    background: #03213f;
    overflow: hidden;
    height: 90vh!important;
}

@media only screen and (max-width: 498px) and (min-width: 427px) {
    body .slick-next::before {
        margin-left: 0px !important;
    }
}

@media (min-width: 632px) and (max-width: 690px) {
    .nav .slick-next {
        right: -29px !important;
        margin-top: -12px !important;
    }
}


/*created for goto recording session*/

@media (min-width: 401px) and (max-width: 499px) {
    #grp_id {
        margin-left: 5px !important;
    }
    .faq-video-player mat-dialog-container {
        /* width: 76vh !important; */
        /* height: 48vh !important; */
        height: 26vh !important;
        margin-top: 50px !important;
    }
    .faq-video-player {
        max-Width: 80vw !important;
        height: 35vh !important;
    }
    .live .slick-prev {
        left: -48px !important;
        margin-top: -55px !important;
    }
    .live .slick-next {
        right: -57px !important;
        margin-top: -58px !important;
    }
}

@media (min-width: 301px) and (max-width: 399px) {
    #grp_id {
        margin-left: 3px !important;
    }
    .faq-video-player {
        height: 39vh !important;
        max-Width: 80vw !important;
    }
    .faq-video-player mat-dialog-container {
        width: 76vh !important;
        /* height: 40vh !important; */
        height: 23vh !important;
    }
    #mat-dialog {
        width: 100% !important;
        height: 100% !important;
    }
    #mat-dialog {
        width: 100% !important;
        height: 100% !important;
    }
    #mat-dialog {
        width: 100% !important;
        height: 100% !important;
    }
    /* .mat-dialog-container .ng-trigger-dialogContainer {
        height: 100%;
    } */
}

.faq-video-player .mat-dialog-container {
    padding: 0 !important;
    margin: 0 !important;
    overflow: hidden !important;
    background-color: black;
}

@media (min-width: 701px) and (max-width: 799px) {
    /* .cdk-overlay-pane , */
    .faq-video-player {
        /* width: 100vh !important; */
        max-Width: 90vw !important;
        height: 40vh !important;
    }
}

@media (min-width: 800px) and (max-width: 959px) {
    /* .cdk-overlay-pane , */
    .faq-video-player {
        /* width: 100vh !important; */
        max-Width: 90vw !important;
        height: 40vh !important;
    }
    .live .slick-next {
        right: -26px !important;
        margin-top: -44px !important;
    }
    .live .slick-prev {
        left: -49px !important;
        margin-top: -54px !important;
    }
}

@media (min-width: 301px) and (max-width: 358px) {
    .faq-video-player mat-dialog-container {
        width: 76vh !important;
        /* height: 40vh !important; */
        height: 30vh !important;
    }
}

@media (min-width: 500px) and (max-width: 600px) {}

@font-face {
    font-family: "Cooper";
    src: local("Cooper"), url(./assets/fonts/COOPBL.ttf) format("truetype");
}

@font-face {
    font-family: "Montserrat-Bold";
    src: local("Montserrat-Bold"), url(./assets/fonts/Montserrat-Bold.ttf) format("truetype");
}

@font-face {
    font-family: "Montserrat-Regular";
    src: local("Montserrat-Regular"), url(./assets/fonts/Montserrat-Regular.ttf) format("truetype");
}

@font-face {
    font-family: "Montserrat-Light";
    src: local("Montserrat-Light"), url(./assets/fonts/Montserrat-Light.ttf) format("truetype");
}

.har-tab .mat-tab-label {
    font-family: Montserrat-Regular;
}

.mat-tab-label .mat-tab-label-content {
    font-family: Montserrat-Regular;
}

.mat-tab-header {
    font-family: Montserrat-Regular;
}

.mat-tab-group {
    font-family: Montserrat-Regular;
}

.mat-form-field-flex {
    font-family: Montserrat-Regular;
}

.mat-raised-button {
    font-family: Montserrat-Regular;
}

.mat-table {
    font-family: Montserrat-Regular !important;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
    font-family: Montserrat-Regular !important;
}

@media (min-width: 333px) and (max-width: 400px) {
    .mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination {
        display: none !important;
    }
}

.page-wrapper1 {
    max-width: 100% !important;
}