/*******************
 sales chart
*******************/
.sales {
  position: relative;
  height: 270px;

  .ct-series-a .ct-area {
    fill-opacity: 0.2;
    fill: url(#gradient);
  }

  .ct-series-a .ct-line,
  .ct-series-a .ct-point {
    stroke: $info;
    stroke-width: 3px;
  }

  .ct-series-b .ct-area {
    fill: $gray-400;
    fill-opacity: 0.1;
  }

  .ct-series-b .ct-line,
  .ct-series-b .ct-point {
    stroke: $gray-400;
    stroke-width: 3px;
  }

  .ct-series-a .ct-point,
  .ct-series-b .ct-point {
    stroke-width: 5px;
  }

  .ct-grid {
    stroke-width: 0.5px;
  }
}
